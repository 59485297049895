import useTranslation from 'next-translate/useTranslation';
import Image from 'next/legacy/image';
import PriceLabel from '@components/molecules/PriceLabel/PriceLabel';
import type { AxfoodBasicProductViewModel } from '@occ/api-client';
import ProductQuantityInputField from '@molecules/ProductQuantityInputField/ProductQuantityInputField';
import useResponsive from '@hooks/useResponsive';
import {
  StyledImageContainer,
  StyledListItemSavePrice,
  StyledPriceLabelWrapper,
  StyledProductInfo,
  StyledProductListItem,
  StyledProductListItemRow,
  StyledProductListItemWrapper,
  StyledText,
  StyledWillysImagePlaceholder,
} from './BuyAllProductsComponent.styles';
import WilllysImagePlaceholder from '@icons/non-standard/WILLYS-IMAGE-PLACEHOLDER-72px.svg';
import { useProductImage } from '@hooks/useProductImage';
import { forwardRef } from 'react';

interface Props {
  product: AxfoodBasicProductViewModel;
  quantity?: number;
  quantityCallback?: (quantity: number) => void;
}

const BuyAllProductsListItem = forwardRef<HTMLDivElement, Props>(
  ({ product, quantity, quantityCallback }: Props, ref) => {
    const { t } = useTranslation('product');
    const { isMobile } = useResponsive();
    const { productImageURL, productImageAltText, onImageLoadError } = useProductImage(product);

    const promotion = product?.potentialPromotions?.[0];
    const hasPromotion = !!promotion;
    const hasMixMatch = promotion?.realMixAndMatch === true;
    const hasSavePriceLabel = !!promotion?.conditionLabel?.length;
    const hasComparePrice = (product?.comparePrice !== '' && product?.comparePriceUnit) || null;
    // @ts-ignore
    const lowestHistoricalPrice = promotion?.lowestHistoricalPrice;
    const isItemDisabled = product?.outOfStock || typeof quantity === 'undefined' || quantity <= 0;

    return (
      <StyledProductListItem data-testid="buy-all-products-list-item" ref={ref}>
        <StyledProductListItemWrapper disabled={isItemDisabled}>
          <StyledProductListItemRow>
            <StyledImageContainer>
              {product ? (
                product?.image && (
                  <Image
                    unoptimized
                    itemProp="image"
                    alt={productImageAltText}
                    src={productImageURL}
                    width={isMobile ? 56 : 72}
                    height={isMobile ? 56 : 72}
                    onError={onImageLoadError}
                  />
                )
              ) : (
                <StyledWillysImagePlaceholder svg={WilllysImagePlaceholder} />
              )}
              {product && (
                <StyledPriceLabelWrapper>
                  {hasSavePriceLabel &&
                    (!lowestHistoricalPrice || lowestHistoricalPrice?.value === product.priceValue || hasMixMatch) && (
                      <StyledListItemSavePrice>
                        {hasMixMatch
                          ? promotion?.conditionLabelFormatted
                            ? promotion?.conditionLabelFormatted
                            : promotion?.conditionLabel
                          : promotion?.conditionLabel}
                      </StyledListItemSavePrice>
                    )}
                  <PriceLabel variant="list" product={product} />
                </StyledPriceLabelWrapper>
              )}
            </StyledImageContainer>

            <StyledProductInfo>
              {product && (
                <>
                  <StyledText type="subhead">{product?.name}</StyledText>
                  <StyledText type="detail" size="small" color="gray">
                    {product?.productLine2}
                  </StyledText>

                  {!hasPromotion && (
                    <StyledText type="detail" size="small" color="gray">
                      {hasComparePrice
                        ? `${t('product->price->compare')} ${product?.comparePrice}/${product?.comparePriceUnit}`
                        : t('product->price->compare-missing')}
                    </StyledText>
                  )}

                  {hasPromotion && (
                    <StyledText type="detail" size="small" color="red">
                      {promotion.comparePrice
                        ? `${t('product->price->compare')} ${
                            (promotion.comparePrice || '').indexOf('/') > -1
                              ? promotion.comparePrice
                              : `${promotion.comparePrice}/${product.comparePriceUnit}`
                          }`
                        : t('product->price->compare-missing')}

                      {`${promotion?.redeemLimitLabel ? ` • ${promotion?.redeemLimitLabel}` : ''}`}
                    </StyledText>
                  )}
                </>
              )}
            </StyledProductInfo>
          </StyledProductListItemRow>
        </StyledProductListItemWrapper>

        <ProductQuantityInputField
          product={product}
          variant="cart"
          sizeOverride={isMobile ? 'xsmall' : 'medium'}
          listName={`buy_all_products_component`}
          quantity={quantity}
          quantityCallback={quantityCallback}
          overrideHidingElements
        />
      </StyledProductListItem>
    );
  }
);

BuyAllProductsListItem.displayName = 'BuyAllProductsListItem';

export default BuyAllProductsListItem;
