import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';
import Link from '@atoms/Link/Link';

export const StyledSnackBar = styled.div<{ slideIn: boolean | null; bottomPosition?: number }>`
  background-color: ${variables.colors.black};
  color: ${variables.colors.white};
  padding: ${rem(20)};
  height: auto;
  display: flex;
  gap: ${rem(20)};
  align-items: center;
  transition: transform 0.5s ease;
  box-shadow: 0 ${rem(8)} ${rem(28)} 0 rgba(0, 0, 0, 0.5);
  border-radius: ${rem(8)};
  transform: translateX(calc(100% + ${rem(variables.size.containerPaddingMobileSmall)}));

  ${Config.MEDIA.FROM_MOBILE} {
    transform: translateX(calc(100% + ${rem(variables.size.containerPaddingMobile)}));
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    transform: translateX(calc(100% + ${rem(24)}));
  }

  ${(props) =>
    props.slideIn &&
    css`
      transform: translateX(0) !important;
    `}
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  gap: ${rem(10)};
  flex: 1;
`;

export const StyledLink = styled(Link)`
  font-size: ${rem(13)};
  color: ${variables.colors.white} !important;
  text-decoration: underline;
  white-space: nowrap;
`;

export const StyledLinkWrapper = styled.div`
  display: flex;
  gap: ${rem(20)};
`;

export const StyledSnackbarWrapper = styled.div<{ bottomPosition?: number }>`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: ${(props) =>
    props.bottomPosition ? rem(props.bottomPosition) : rem(variables.size.containerPaddingMobileSmall)};
  right: ${rem(variables.size.containerPaddingMobileSmall)};
  width: calc(100% - ${rem(2 * variables.size.containerPaddingMobileSmall)});
  gap: ${rem(12)};
  z-index: ${variables.zIndex.modal + 1};

  ${Config.MEDIA.FROM_MOBILE} {
    right: ${rem(variables.size.containerPaddingMobile)};
    width: calc(100% - ${rem(2 * variables.size.containerPaddingMobile)});
    bottom: ${(props) =>
      props.bottomPosition ? rem(props.bottomPosition) : rem(variables.size.containerPaddingMobile)};
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    bottom: ${rem(24)};
    right: ${rem(24)};
    width: auto;
    max-width: ${rem(358)};
  }
`;
